
import {
  Component, Vue, Watch, Ref,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Invoice from '@/models/Invoice'
import Company from '@/models/Company'
import ViewModel from '@/models/ViewModel'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'

import FooterNav from '@/components/FooterNav/FooterNav.vue'
import moment from 'moment'
import Reconciliation from '@/models/Reconciliation'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import TermsPicker from '@/components/TermsPicker/TermsPicker.vue'
import Terms from '@/models/Terms'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import {
  InvoicePaymentTerms,
  InvoiceTemplates,
  InvoiceDeliveryMode,
} from '@/models/interface/Common'
import IconAction from '@/components/IconAction/IconAction.vue'
import DataTable from '@/components/DataTable/index.vue'
import { clone } from 'lodash'
import MediaPlan from '@/models/MediaPlan'
import WebMessage from '@/models/WebMessage'
import InvoicePrintView from './Components/InvoicePrintView.vue'

import InvoiceItemsTable from './Components/InvoiceItemsTable.vue'
import MediaOceanDetails from './Components/MediaOceanDetails.vue'

import simple_invoice_item_fields from './simple-invoice-item-field'

import ReconciliationItemsTable from './Components/ReconciliationItemsTable.vue'
import MediaPlanView from '../Sales/MediaPlan/MediaPlanView.vue'
import { invoice_type_options, invoice_detail_options } from './options'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    InvoicePrintView,
    FormWizard,
    TabContent,
    FormInput,
    CompanyPicker,
    FooterNav,
    PageHeader,
    InvoiceItemsTable,
    MediaOceanDetails,
    ReconciliationItemsTable,
    TermsPicker,
    ModelFileUploader,
    UserPicker,
    IconAction,
    DataTable,
    MediaPlanView,
  },
})
export default class InvoiceEdit extends ViewModel {
  @Ref() clientSelector!: HTMLFormElement

  @Ref() form_wizard!: any

  @Ref() validator!: any

  public invoice: Invoice = new Invoice()

  public selected_items: any = []

  public custom_items: any = []

  public step: number = 1

  public busy = true

  public togglingMediaOcean: boolean = false

  public active_tab_index: number = 0

  public reconciliation_items: Reconciliation[] = []

  public automated_files: any = [
    /* {
      name: 'Add Delivery Report',
      automated: true,
      icon: 'file-excel',
      code: 'delivery_report',
    }, */
  ]

  public modal: any = {
    create: false,
  }

  // public invoice_group_options: any = [
  //   { text: 'Media Plan', value: 'media_plan' },
  //   { text: 'Single Item', value: 'single' },
  //   { text: 'Line Item', value: 'media_plan_item' },
  // ]

  // public invoice_type_options: any = [
  //   { text: 'Default', value: 'default' },
  //   { text: 'Media Ocean', value: 'media_ocean' },
  // ]

  public get options() {
    return {
      invoice_type_options,
      invoice_detail_options,
      InvoiceTemplates,
      InvoiceDeliveryMode,
    }
  }

  public discount_modes = [
    {
      text: 'Percent',
      value: 'percent',
    },
    {
      text: 'Amount',
      value: 'amount',
    },
  ]

  public get payment_terms() {
    return InvoicePaymentTerms
  }

  public selected_term: string = ''

  public ref_amount: number = 0

  @Watch('invoice.group_mode')
  public onGroupModeChange(val: any, oldVal: any) {
    if (!this.busy && this.invoice.items.length) {
      this.invoice.rebuildItems(this.selected_items, true)
    }
  }

  @Watch('invoiceTerms')
  public onInvoiceTermschange(val: any) {
    if (val && val === 'custom') {
      this.invoice.terms_id = 'custom'
    }

    if (this.invoice.terms?.length) {
      setTimeout(() => {
        Vue.set(this.$refs.termSelector!, 'local_terms', this.invoice.terms)
      }, 1000)
    }
  }

  @Watch('isMediaOcean')
  public onMediaOceanChange(val: any) {
    if (val) this.toggleTabOrder()
  }

  @Watch('client_id')
  public clientIdChanged(id: any) {
    if (id) this.loadClient(id)
  }

  public created() {
    if (this._isEditing) {
      Invoice.findInvoiceWithReconciliation(this.$route.params.id).then((invoice: Invoice) => {
        if (invoice instanceof Invoice) this.invoice = invoice
        this.ref_amount = clone(this.invoice.total)
        this.invoice.created_at = moment(this.invoice.created_at).format('YYYY-MM-DD')
        this.loadReconciliationItems().then(data => {
          this.selected_items = data.filter(r =>
            this.invoice.items.some(i => i.reconciliation_ids.includes(r.id)))
          this.busy = false
        })
      })
    } else {
      this.busy = false
    }
  }

  public fectMediaPlanNumber(id: string): string {
    let target = this.reconciliation_items.find(
      (item: Reconciliation) => item.media_plan?.id === id,
    )

    if (target) return `${target.media_plan?.number}`

    return ''
  }

  public mounted() {
    if (this._isEditing) {
      setTimeout(() => {
        this.updateTerms()
      }, 1000)
    }
  }

  public toggleTabOrder() {
    this.togglingMediaOcean = true
    setTimeout(() => {
      this.togglingMediaOcean = false
    }, 100)
  }

  public get masks() {
    return {
      currencyMask,
      percentageMask,
    }
  }

  /**
   * Returns the mask object based on the invoice.discount_mode
   */
  public get maskByDiscountMode() {
    if (this.invoice.discount_mode !== 'percent') return currencyMask
    return percentageMask
  }

  public get client_id() {
    return this.invoice.client_id
  }

  public get terms_id() {
    return this.invoice.terms_id
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  public get pageTitle() {
    if (this.$route.params.id) return 'Details'
    return 'New invoice'
  }

  public get invoiceTerms() {
    if (!this.invoice.terms_id && this.invoice.terms?.length) {
      return 'custom'
    }
    return this.invoice.terms_id
  }

  /**
   * Group mode options
   *
   * When the invoice type is media_ocean it will return option Line item
   */
  public get groupOptions() {
    if (this.isMediaOcean) {
      return this.options.invoice_detail_options.filter((op: any) => op.value === 'media_plan_item')
    }
    return this.options.invoice_detail_options
  }

  /**
   * based on the invoice's type change table fields.
   * For now just use one
   */
  public get items_fields() {
    return simple_invoice_item_fields
  }

  /**
   * Returns a boolean to confirm if the active tab is the last one in the form wizard based on some conditions
   */
  public get lastStep() {
    if (this.isMediaOcean && this.reconciliation_items.length) {
      return this.step === 5
    }
    if (this.isMediaOcean || this.reconciliation_items.length) {
      return this.step === 4
    }
    return this.step === 3
  }

  public get isMediaOcean() {
    return this.invoice.isLinear
  }

  public updateTerms() {
    if (
      this.invoice.terms_id
      && this.invoice.terms_id.length
      && this.invoice.terms_id !== 'custom'
    ) {
      Terms.get(this.invoice.terms_id).then(data => {
        if (data) {
          setTimeout(() => {
            // @ts-ignore
            Vue.set(this.$refs.termSelector, 'label_name', data.name)
          }, 2000)
        }
      })
    }
  }

  /**
   * Loads the client/company informations by id
   */
  public loadClient(client_id: string) {
    Company.find(client_id).then(result => {
      this.invoice.client = result
      this.loadReconciliationItems()

      if (!this.invoice.id && result && result.billing_info.invoice_detail) {
        this.invoice.group_mode = result.billing_info.invoice_detail
        this.invoice.template_id = result.billing_info.invoice_template
        this.invoice.metadata.invoice_mode = result.billing_info.invoice_delivery_driver
      }

      return result
    })
  }

  /**
   * Loads the reconciliated items base on the client_id in the invoice
   */
  public loadReconciliationItems() {
    return Reconciliation.getInvoicebleItems(this.invoice).then(data => {
      this.reconciliation_items = data
      if (this.$route.params.id) {
        setTimeout(() => {
          this.form_wizard?.activateAll()
        }, 1000)
      }
      return data
    })
  }

  /**
   * When invoice.type === media_ocean set the default configs and options
   */
  public setMediaOceanDefaults(selected: any) {
    if (selected.value !== 'default') {
      this.invoice.group_mode = 'media_plan_item'
    }
  }

  public wizardUpdateLocal() {
    this.active_tab_index = this.form_wizard.activeTabIndex
  }

  public nextItem() {
    this.form_wizard.nextTab()

    this.wizardUpdateLocal()
  }

  public prevItem() {
    this.form_wizard.prevTab()
    this.wizardUpdateLocal()
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    return true
  }

  /**
   * Runs the function that handles the save / update.
   *
   * This function you can do some extra validations before the handler
   */
  public onSubmit() {
    this.loading = true
    this.invoice.items.map(i => {
      if (i.id?.includes('custom-')) i.id = null
      return i
    })

    // @ts-ignore
    this.invoice.media_plan_ids = [
      ...new Set(this.selected_items.map((item: any) => item.media_plan_id)),
    ]

    if (this._isEditing && this.ref_amount != this.invoice.total && this.invoice.isBooksClosed) {
      WebMessage.confirm(
        'Are you sure you want to change the invoice total amount? This action will generate a Credit Memo automatically.',
        'Books Closed!',
        {
          okTitle: 'Yes',
          cancelText: 'No',
        },
      ).then((result: any) => {
        if (result) {
          this.executeSubmit()
          return
        }
        this.loading = false
      })
      return
    }

    // submit handler
    this.executeSubmit()
  }

  public executeSubmit() {
    this.invoice
      .save()
      .then((response: any) => {
        this.loading = false
        if (response.status == 200) {
          this.cancel()
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    if (this.$route.query.ref && this.$route.query.ref === 'MyDashboard') {
      this.$router.push({ name: 'MyDashboard' })
    } else {
      this.$router.push({ name: 'Invoices' })
    }
  }
}

export default [
  {
    key: 'id',
    label: 'Id',
    class: 'text-center align-middle',
    show: false,
  },

  {
    key: 'number',
    label: '#',
    class: 'text-center align-middle',
    show: true,
  },

  {
    key: 'invoice_id',
    label: 'Invoice id',
    class: 'text-center align-middle',
    show: false,
  },

  {
    key: 'type',
    label: 'Type',
    show: true,
    class: 'text-center align-middle text-capitalize',
  },

  {
    key: 'name',
    label: 'Item',
    class: 'text-center align-middle fixed-width',
    show: true,
  },

  {
    key: 'product',
    label: 'Product',
    class: 'text-center align-middle fixed-width-sm',
    show: true,
  },

  {
    key: 'start_at',
    label: 'Start Date',
    class: 'text-center align-middle fixed-width-sm',
    show: true,
  },
  {
    key: 'end_at',
    label: 'End Date',
    class: 'text-center align-middle fixed-width-sm',
    show: true,
  },
  {
    key: 'booked',
    label: 'Booked',
    class: 'text-center align-middle',
    show: true,
    type: 'number',
  },
  {
    key: 'delivered',
    label: 'Delivered',
    class: 'text-center align-middle',
    show: true,
    type: 'number',
  },
  {
    key: 'billable',
    label: 'Billable',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'model',
    label: 'Model',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'gross_rate',
    label: 'Gross rate',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'net_rate',
    label: 'Net rate',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'gross_total',
    label: 'Gross total',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'net_total',
    label: 'Net total',
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    show: true,
    class: 'text-center align-middle',
  },
]


import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'
import Reconciliation from '@/models/Reconciliation'
import { clone } from 'lodash'
import moment from 'moment'
import table_fields from '../reconciliation-item-fields'

@Component({
  components: {
    Widget,
    DataTable,
    IconAction,
    FormInput,
    SelectPicker,
  },
})
export default class ReconciliationItemsTable extends ViewModel {
  @Ref() readonly itemsTable!: HTMLFormElement

  public items_fields: any = []

  @Prop({ default: false })
  public is_media_ocean!: boolean

  @Prop()
  public invoice: any

  @Prop()
  public value: any

  @Prop()
  public items_list!: any

  public reconciliation_items: Reconciliation[] = []

  public query: any = ''

  public records: any = 0

  public selected_items: Array<any> = []

  public loading: boolean = true

  public ready: boolean = false

  public get show_fields() {
    return this.items_fields.filter((f: any) => f.show)
  }

  public get client_id() {
    return this.invoice.client_id
  }

  mounted() {
    this.items_fields = table_fields
    if (this.items_list.length) {
      this.reconciliation_items = this.items_list
      this.itemsTable.refresh()
      this.loading = false
      this.ready = true
      setTimeout(() => {
        this.checkSelectedIems()
      }, 200)
    }

    this.selected_items = this.value
  }

  /**
   * Toggles the checkbox in thw row.
   *
   * It checks localy for the selected_items.
   *
   * After updating selected_items, it will be sent to parent via emit('input')
   *
   */
  public toggleRow(row: any) {
    // check if exists first, if it does remove it
    let index = this.selected_items.findIndex(s => s.id === row.item.id)

    if (index > -1) {
      this.selected_items.splice(index, 1)
      return
    }

    let can_add = true

    // if media_ocean, can add one media_plan and N items that belongs to only one media_plan
    if (this.is_media_ocean) {
      if (this.selected_items.length) {
        can_add = this.selected_items.some(
          (selected: any) =>
            // TODO james: change e.media_plan_name to the incomming
            selected.media_plan.name === row.item.media_plan.name,
        )
      }

      if (can_add) this.selected_items.push(clone(row.item))
      else {
        row.item.checkbox = false
        WebMessage.warning(
          'This is a Media Ocean invoice, you can include only one Media Plan per invoice',
        )
      }
    } else {
      this.selected_items.push(clone(row.item))
    }

    this.$emit('input', clone(this.selected_items))

    this.invoice.rebuildItems(this.selected_items)
    this.invoice.media_plan_ids = [...new Set(this.selected_items.map((i: any) => i.media_plan.id))]
    if (this.selected_items[0]) {
      this.invoice.metadata.header.broadcast_month = moment(this.selected_items[0].billing_month).format('YYYY-MM-DD')
    } else {
      this.invoice.metadata.header.broadcast_month = clone(this.invoice.created_at)
    }
  }

  /**
   * Check selected_items checkbox based on invoice.reconciliation_ids
   */
  public checkSelectedIems() {
    const ids = this.invoice.reconciliation_ids

    // this.itemsTable.refresh()

    this.itemsTable.rows.forEach((row: any) => {
      if (ids.includes(row.id)) {
        row.checkbox = true
      } else {
        row.checkbox = false
      }
    })

    setTimeout(() => {
      this.itemsTable.refresh()
    }, 300)
  }
}

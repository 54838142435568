
import Invoice from '@/models/Invoice'
import { Component } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import MediaPlan from '@/models/MediaPlan'
import SelectOption from '@/models/interface/SelectOption'
import IconAction from '@/components/IconAction/IconAction.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import { isCampagingInDateRange } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import InvoicePrintView from './Components/InvoicePrintView.vue'
import MediaOceanPrintView from './Components/MediaOceanPrintView.vue'
import MediaPlanView from '../Sales/MediaPlan/MediaPlanView.vue'

@Component({
  components: {
    Widget,
    InvoicePrintView,
    MediaOceanPrintView,
    IconAction,
    MediaPlanView,
    SelectPicker,
  },
})
export default class InvoiceEdit extends ViewModel {
  public invoice: Invoice = new Invoice()

  public view_mode = 'invoice'

  public busy: boolean = true

  public componentView: string = 'invoice'

  public invoice_plan_target: string = ''

  public show_included_items_only = true

  public showDetails = false

  public created() {
    // Init prefered view mode
    let view_mode = window.localStorage.getItem('invoice_view_mode')

    if (!this.user.isSystem) {
      if (view_mode) {
        this.view_mode = view_mode === 'media_plan' ? 'columns' : view_mode
      } else {
        this.view_mode = 'columns'
      }
    }
    let view = this.$route.query.view
    if (view && typeof view === 'string') {
      this.componentView = view
    }
    Invoice.find(this.$route.params.id).then((invoice: Invoice) => {
      if (invoice instanceof Invoice) this.invoice = invoice
      if (invoice.media_plans.length > 0 && invoice.media_plans[0].id) {
        this.invoice_plan_target = invoice.media_plans[0].id
      }
      if (!this.user.isSystem) {
        if (invoice.type === 'media_ocean' || invoice.type === 'strata') {
          this.componentView = 'schedule'
        } else {
          this.componentView = 'invoice'
        }
      }
      this.busy = false
    })
  }

  public editInvoice() {
    this.$router.push(`/app/invoice/${this.invoice.id}`)
  }

  public toggleView() {
    if (this.componentView === 'invoice') this.componentView = 'schedule'
    else this.componentView = 'invoice'
  }

  public setViewMode(mode: string) {
    if (
      mode !== 'invoice'
      && this.invoice.id
      && this.invoice.items.every(i => i.reconciliations.length === 0)
      && this.invoice.media_plans.length > 0
    ) {
      this.busy = true
      Invoice.findInvoiceWithReconciliation(this.invoice.id).then((invoice: Invoice) => {
        if (invoice instanceof Invoice) this.invoice = invoice
        // if (this.view_mode !== mode) {
        //   this.showDetails = false
        // }
        this.view_mode = mode
        window.localStorage.setItem('invoice_view_mode', mode)
        this.busy = false
      })
      return
    }
    // if (this.view_mode !== mode) {
    //   this.showDetails = false
    // }
    this.view_mode = mode

    window.localStorage.setItem('invoice_view_mode', mode)
  }

  public get mediaPlanOptions() {
    return this.invoice.media_plans.map(
      (plan: MediaPlan) => new SelectOption(`#${plan.number} - ${plan.name}`, plan.id),
    )
  }

  public editMediaPlan(id: any) {
    this.$router.push(`/app/sales/media_plan/${id}?ref=Invoices`)
  }

  public getIncludedItems(invoice: Invoice): string[] {
    if (!invoice.id) return []

    let included_line_item_ids: string[] = []
    invoice.items.forEach(i =>
      i.reconciliations.forEach(r => {
        included_line_item_ids.push(r.media_plan_item_id)
      }))

    return [...new Set(included_line_item_ids)]
  }

  public getElegibleItems(invoice: Invoice) {
    let elegible_line_items = invoice.media_plans.reduce(
      (acc: any, plan: any) =>
        acc.concat(
          plan.line_items
            .filter((i: MediaPlanItem) =>
              isCampagingInDateRange(
                invoice.billing_period.start_at,
                invoice.billing_period.end_at,
                i.start_at,
                i.end_at,
              ))
            .map((i: any) => {
              i = MediaPlanItem.toObject(i)
              i.type = plan.type
              i.agency_commission = plan.agency_commission
              return i
            }),
        ),
      [],
    )

    return elegible_line_items
  }

  public getElegibleButNotIncluded(invoice: Invoice) {
    // TODO FIX, not working
    let elegible_line_items = this.getElegibleItems(invoice)
    let included_line_item_ids = this.getIncludedItems(invoice)

    return elegible_line_items
      .filter((i: MediaPlanItem) => i.id && !included_line_item_ids.includes(i.id))
      .map((i: MediaPlanItem) => i.id)
  }

  public download() {
    // this.invoice.download()
  }
}


import { Component, Prop, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import Invoice from '@/models/Invoice'
import Reconciliation from '@/models/Reconciliation'

import SelectOption from '@/models/interface/SelectOption'
import InvoiceItem from '@/models/InvoiceItem'
import simple_invoice_item_fields from '../simple-invoice-item-field'

@Component({
  components: {
    Widget,
    DataTable,
    IconAction,
    FormInput,
    SelectPicker,
    DatePicker,
  },
})
export default class InvoiceItemsTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop() // selected_items
  value!: any

  @Prop() // Invoice
  invoice!: Invoice

  public fieldFilters: any = {}

  public query: string[] = []

  public records: number = 0

  public fields: any = []

  public sortDesc: boolean = false

  public custom_items: any = []

  public invoice_models: SelectOption[] = [
    new SelectOption('CPM', 'cpm'),
    new SelectOption('Flat', 'flat'),
  ]

  public order_types: any = [
    {
      name: 'Cash',
      value: 'cash',
    },
    {
      name: 'Trade',
      value: 'trade',
    },
  ]

  public bool_options = [
    {
      name: 'Yes',
      value: 'Y',
    },
    {
      name: 'No',
      value: 'N',
    },
  ]

  public spot_length = [
    {
      name: '15',
      value: '15',
    },
    {
      name: '30',
      value: '30',
    },
    {
      name: '60',
      value: '60',
    },
  ]

  public get isMediaOcean() {
    return this.invoice.isLinear
  }

  public get items_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  /**
   * List the items and the custom items
   */
  public get invoiceItems() {
    return this.invoice.items
  }

  public mounted() {
    this.fields = simple_invoice_item_fields
  }

  public popoverId(id: any) {
    return `popover-${id}`
  }

  public day_of_week_human(run_date: any) {
    if (!run_date) return '-'
    return moment(run_date, 'YYMMDD').format('dddd')
  }

  /**
   * Apply the new item name
   *
   * The  popover input
   */
  public applyNameChange(row: any, ref_name: string = 'edit_name', key: string = 'name') {
    // @ts-ignore
    const input = this.$refs[`${ref_name}_${row.item.id}`]

    // @ts-ignore
    row.item[key] = input.localValue

    let indx = this.invoice.items.findIndex(selected_item => selected_item.id === row.item.id)
    // @ts-ignore
    this.invoice.items[indx][key] = input.local_value ?? input.localValue

    this.$root.$emit('bv::hide::popover')
  }

  /**
   * Remove from items
   * This function removes the any type of item
   */
  public removeFromItems(row: any) {
    if (row.item.type === 'custom') {
      this.removeCustomItem(row.item)
      return
    }
    this.removeItem(row.item)

    this.dataTable.refresh()
  }

  /**
   * Remove item from custom_items
   */
  public removeCustomItem(item: any) {
    // let index = this.custom_items.findIndex((i: any) => i.id === item.id)
    let itemsIndex = this.invoice.items.findIndex((i: any) => i.id === item.id)
    if (itemsIndex >= 0) {
      // this.custom_items.splice(index, 1)
      this.invoice.items.splice(itemsIndex, 1)
    }
  }

  /**
   * Remove item from invoice.items and InvoiceEdit selected_items
   */
  public removeItem(item: any) {
    const removed = this.invoice.items.filter(_item => _item.id !== item.id)

    this.invoice.items = removed

    let filtered = this.invoice.items.filter(i => i.type !== 'custom')

    const selected_items = filtered.reduce((acc, obj: any) => acc.concat(obj.reconciliations), [])

    this.$emit('input', selected_items)

    this.invoice.rebuildItems(selected_items)

    this.dataTable.refresh()
  }

  /**
   * Adds custom item to custom_items
   */
  public addCustomItem() {
    let item = new InvoiceItem()
    item.type = 'custom'

    this.invoice.items.push(item)

    this.dataTable.refresh()
  }
}

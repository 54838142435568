
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Watch } from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'

@Component({
  components: {
    FormInput,
  },
})
export default class MediaOceanDetails extends ViewModel {
  @Prop()
  public value!: any

  @Prop()
  public type!: string

  public metadata: any = {}

  @Watch('metadata')
  public onInvoiceChange(val: any) {
    if (val) this.$emit('input', val)
  }

  mounted() {
    this.metadata = this.value
  }
}


import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Invoice from '@/models/Invoice'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { currencyMask } from '@/models/interface/Masks'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'
import Payment from '@/models/Payment'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { PaymentMethods } from '@/models/interface/Common'
import {
  clone as _clone, isArray, isEqual, isObject, isString, transform,
} from 'lodash'
import moment from 'moment'
import util from '@/mixins/util'
import { resolve } from 'path'
import FaqModal from '@/components/FaqModal/FaqModal.vue'
import InvoiceBadgeStatus from './Components/InvoiceBadgeStatus.vue'
import home_table_options from './home-table-fields'
import InvoicePrintView from './Components/InvoicePrintView.vue'
import MediaOceanPrintView from './Components/MediaOceanPrintView.vue'

Vue.directive('mask', VueMaskDirective)
@Component({
  components: {
    Widget,
    InvoiceBadgeStatus,
    IconAction,
    SearchInput,
    DataTable,
    FormInput,
    DatePicker,
    SelectPicker,
    InvoicePrintView,
    MediaOceanPrintView,
    FaqModal,
  },
})
export default class InvoiceHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public show_filter_helper: boolean = false

  public invoice!: Invoice

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public selected: string[] = []

  public fieldFilters: any = {
    created_at: '',
    due_at: '',
    updated_at: '',
  }

  public query: string[] = []

  public fields: Array<any> = []

  public invoices: Invoice[] = []

  public action_acknowledged: boolean = false

  public order_type = 'all'

  public download_formats: string[] = ['pdf', 'txt']

  public invoice_index: number = 0

  public invoice_preview_mode: any = 'invoice'

  public history: Array<any> = []

  public invoice_items_history: Array<any> = []

  public history_page: number = 1

  public history_page_size: number = 25

  public history_records: number = 0

  public history_loading: boolean = true

  public loaded_informations: any = {}

  public invoice_payments: any = []

  public mark_as_sent: boolean = false

  public close_books_date: string = moment().subtract(1, 'month').format('YYYY-MM')

  public get current_books_closed_date(): ?string {
    const system = getModule(SystemtModule)

    if (
      system.env_vars.finance
      && system.env_vars.finance.accounting
      && system.env_vars.finance.accounting.closed_books_date
    ) {
      return moment(system.env_vars.finance.accounting.closed_books_date)
        .endOf('day')
        .format('YYYY-MM-DD')
    }

    return null
  }

  public payment_table_fields = [
    {
      key: 'amount',
      label: 'Amount',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },

    {
      key: 'payment_method',
      label: 'Payment Method',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },

    {
      key: 'created_at',
      label: 'Paid At',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
  ]

  public history_fields: any = [
    {
      key: 'user',
      label: 'User',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'event',
      label: 'Action',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'created_at',
      label: 'Date',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'auditable_type',
      label: 'Type',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
    {
      key: 'action',
      label: '',
      class: 'text-center align-middle text-capitalize',
      show: false,
    },
  ]

  public history_details_fields = [
    {
      key: 'key',
      label: 'Field',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'old',
      label: 'Old value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'new',
      label: 'New value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
  ]

  public history_details_fields_created = [
    {
      key: 'key',
      label: 'Field',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
    {
      key: 'new',
      label: 'New value',
      class: 'text-center align-middle text-capitalize',
      show: false,
      thClass: 'font-weight-bold',
    },
  ]

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get invoice_preview_options() {
    return this.invoices.map((invoice: Invoice, index: number) => ({
      value: index,
      text: invoice.name,
    }))
  }

  public get download_format_options(): any {
    let ret = [
      { value: 'pdf', name: 'Invoice PDF' },
      { value: 'files', name: 'Attached Files' },
    ]

    if (this.hasLinearInvoices) {
      ret.push({ value: 'txt', name: 'Media Ocean TXT Invoice' })
      ret.push({ value: 'linear_pdf', name: 'Media Ocean PDF Invoice' })
    }

    return ret
  }

  public get payment_options() {
    return PaymentMethods
  }

  public order_type_options = [
    {
      name: 'Cash & Trade',
      value: 'all',
    },
    {
      name: 'Cash Only',
      value: 'cash',
    },
    {
      name: 'Trade Only',
      value: 'trade',
    },
  ]

  public get invoiceHistory() {
    return [...this.history, ...this.invoice_items_history]
  }

  public get activeInvoice(): Invoice {
    let ret = this.invoices.find((invoice: Invoice) => invoice.id === this.selected[0])
    if (!ret) {
      ret = new Invoice()
    }
    return ret
  }

  public get hasSelectedUnapprovedInvoices(): boolean {
    return this.selected.some((id: string) => {
      const invoice = this.invoices.find((invoice: Invoice) => invoice.id === id)
      return invoice && invoice.review_status !== 'approved'
    })
  }

  public get hasLinearInvoices() {
    return this.selected.some((id: string) => {
      const invoice = this.invoices.find((invoice: Invoice) => invoice.id === id)
      return invoice && invoice.isLinear
    })
  }

  public sortDesc!: any

  public sortBy!: any

  public overview: any = {
    draft_invoices: 0,
    due_amount: 0,
    error_invoices: 0,
    invoiceble_amount: 0,
    invoiceble_clients: 0,
    invoiceble_media_plan_items: 0,
    invoiceble_media_plans: 0,
    overdue_amount: 0,
    overdue_invoices: 0,
  }

  public modal: any = {
    delete: false,
    history: false,
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'client',
        key: 'client_id',
        description: 'Include only the specified client',
      },
    ],
    media_plan_fields: [
      {
        name: 'media_plan',
        key: 'media_plan_ids',
        description: 'Include only the specified media plan',
      },
    ],
    custom_fields: [
      {
        name: 'status:draft',
        value: 'is:draft',
        description: 'Includes only records with Draft as status',
      },
      {
        name: 'status:paid',
        value: 'is:paid',
        description: 'Includes only records with Paid as status',
      },
      {
        name: 'status:overdue',
        value: 'is:overdue',
        description: 'Includes only records with Overdue as status',
      },
      {
        name: 'status:sent',
        value: 'is:sent',
        description: 'Includes only records with Sent as status',
      },
      {
        name: 'status:partially_paid',
        value: 'is:partially_paid',
        description: 'Includes only records with Partially Paid as status',
      },
      {
        name: 'has:errors',
        value: 'has:errors',
        description: 'Includes only records with errors',
      },
    ],
  }

  public temp_invoice: any = {
    pay_amount: 0,
    due: 0,
  }

  public toggleView() {
    if (this.invoice_preview_mode === 'invoice') this.invoice_preview_mode = 'schedule'
    else this.invoice_preview_mode = 'invoice'
  }

  public clearTempInvoice() {
    this.temp_invoice = {
      pay_amount: 0,
      due: 0,
    }
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get masks() {
    return { currencyMask }
  }

  public runPanic() {
    return this.panic()
  }

  public runPanicSend() {
    return this.panicSend()
  }

  private targets: any = {}

  public async panicSend() {
    let proceed = await WebMessage.confirm(
      'Are you sure that you want to send all the invoices?.',
      'Send Invoices?',
      { okTitle: 'Send Them all!' },
    )
    if (!proceed) {
      return
    }
    let groups: any = {}
    for (let id in this.targets) {
      if (!groups[this.targets[id]]) {
        groups[this.targets[id]] = []
      }
      groups[this.targets[id]].push(id)
    }

    for (let type in groups) {
      await Invoice.send(groups[type], type)
    }
  }

  public async panic() {
    if (this.targets) {
      let batch = false
      let batch_count = 0
      for (let id in this.targets) {
        let original = await Invoice.find(id)
        if (!original) {
          WebMessage.error(`Invoice #${id} not found.`)
          return
        }

        let invoice = _clone(original)
        invoice = await invoice.rebuildInvoice('single', original.group_mode)
        invoice.id = _clone(original.id)
        invoice.number = _clone(original.number)

        let proceed = false
        if (batch) {
          proceed = true
        } else {
          proceed = await WebMessage.confirm(
            `Should we proceed with the invoice fix for invoice #${invoice.number}? It will update the amounts from ${original.total} to ${invoice.total}.`,
            'Fix Invoice',
            { okTitle: 'Fix' },
          )
        }

        if (proceed) {
          if (!batch && batch_count >= 10) {
            batch_count = 0
            batch = await WebMessage.confirm('Should we batch fix all from now on?', 'Batch Fix', {
              okTitle: 'Yes, run Batch',
            })
          }
          await invoice.save()
          batch_count++
          WebMessage.success(`Invoice ${invoice.invoice_number} has been fixed.`)
        } else {
          break
        }
      }
    }
  }

  public invoiceRows(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    this.getInvoicesOverview()

    return Invoice.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.selected = []
      this.records = result.records
      this.loading = false
      this.ready = true
      this.invoices = result.data
      return result.data
    })
  }

  public confirmDelete(invoice: Invoice) {
    if (!this.isQuickbooksLinked()) return
    this.invoice = invoice

    WebMessage.confirm(
      `Are you sure that you want to delete the invoice "<strong>${invoice.name}</strong>"? You won't be able to restore it!`,
      'Delete Invoice',
    ).then((value: boolean) => {
      if (value) {
        this.deleteInvoice()
        this.dataTable.refresh()
      }
    })
  }

  public deleteInvoice() {
    if (!this.isQuickbooksLinked()) return

    this.invoice.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public updateInvoice(send: boolean = false) {
    this.loading = true

    if (
      this.invoices[this.invoice_index].total != this.temp_invoice.total
      && this.temp_invoice.isBooksClosed
    ) {
      WebMessage.confirm(
        'Are you sure you want to change the invoice total amount? This action will generate a Credit Memo automatically.',
        'Books Closed!',
        {
          okTitle: 'Yes',
          cancelText: 'No',
        },
      ).then((result: any) => {
        if (result) {
          this.executeInvoiceUpdate(send)
          return
        }
        this.loading = false
      })
      return
    }

    this.executeInvoiceUpdate(send)
  }

  public executeInvoiceUpdate(send: boolean = false) {
    this.temp_invoice.save().then(() => {
      this.invoices[this.invoice_index] = this.temp_invoice

      setTimeout(() => {
        if (send) {
          Invoice.requestApproval([this.temp_invoice.id]).then(() => {
            this.dataTable.refresh()
            WebMessage.success('Approval request sent!')
          })
        }
        this.$bvModal.hide('invoice-print')
        this.loading = false
        this.dataTable.refresh()
      }, 500)
    })
  }

  public closeBooks() {
    this.$bvModal.show('invoice-close-books-modal')
  }

  public confirmcloseBooks() {
    this.$bvModal.hide('invoice-close-books-modal')
    Invoice.closeBooks(
      moment(`${this.close_books_date}-01`).endOf('month').format('YYYY-MM-DD'),
    ).then((result: any) => {
      if (result) {
        WebMessage.success('Books closed successfully!')
      }
    })
  }

  public async recalculateInvoice(row: Invoice, index: number) {
    this.loading = true
    this.$root.$emit('bv::show::modal', 'invoice-print')
    this.invoice_index = index

    let copied_invoice: Invoice = _clone(this.invoices[index])
    copied_invoice = await copied_invoice.rebuildInvoice('single', copied_invoice.group_mode)
    copied_invoice.id = _clone(this.invoices[index].id)
    copied_invoice.number = _clone(this.invoices[index].number)
    copied_invoice.name = _clone(this.invoices[index].name)

    this.loading = false
    if (copied_invoice._hash !== this.invoices[index]._hash) {
      this.temp_invoice = copied_invoice
      this.$root.$emit('bv::show::modal', 'invoice-print')
    } else {
      WebMessage.info(
        'Looks like nothing has changed. Please review the Media Plans and try again.',
      )
    }
  }

  public editInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}`)
  }

  public viewInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}/view`)
  }

  public recalculateAgencyCommission(invoice: Invoice) {
    if (!invoice) {
      if (this.selected.length === 0) {
        WebMessage.error('Please select at least one invoice to send')
        return
      }
      let periods = [
        ...new Set(
          this.invoices
            .filter((i: Invoice) => i.id && this.selected.includes(i.id))
            .map((i: Invoice) => moment(i.created_at).format('YYYY-MM')),
        ),
      ]

      if (periods.length > 1) {
        WebMessage.error('Please select invoices from the same period')
        return
      }
    } else if (invoice.id) {
      this.selected = [invoice.id]
    }

    WebMessage.confirm(
      this.selected.length === 1
        ? `Are you sure that you want to recalculate the agency commission for the invoice "<strong>#${this.activeInvoice.number} ${this.activeInvoice.name}</strong>"?`
        : 'Are you sure that you want to recalculate the agency commission for the selected invoices?',
      'Recalculate Agency Commission',
      {
        okTitle: 'Recalculate',
      },
    ).then((value: boolean) => {
      if (value) {
        this.loading = true
        Invoice.recalculateAgencyCommission(this.selected)
          .then(() => {
            this.loading = false
            setTimeout(() => {
              this.dataTable.refresh()
            }, 500)
            WebMessage.success(
              'Recalculation complete! Please review the invoices before sending to the client to ensure that all the information is correct.',
            )
          })
          .catch((error: any) => {
            WebMessage.error(
              "We couldn't recalculate the agency commission. Please try again later.",
            )
          })
      }
    })
  }

  public requestApproval(invoice: Invoice) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }

    if (invoice && invoice.id) this.selected = [invoice.id]

    WebMessage.confirm(
      this.selected.length === 1
        ? `Are you sure that you want to send the invoice "<strong>#${this.activeInvoice.number} ${this.activeInvoice.name}</strong>" for approval?`
        : 'Are you sure that you want to request approval for the selected invoices?',
      'Request Approval',
      {
        okTitle: 'Request Approval',
      },
    ).then((value: boolean) => {
      if (value) {
        Invoice.requestApproval(this.selected).then(() => {
          this.dataTable.refresh()
          WebMessage.success('Approval request sent!')
        })
      }
    })
  }

  public download(invoice: Invoice) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'files']
    if (this.hasLinearInvoices) {
      this.download_formats = ['linear_pdf', 'txt', 'files']
      this.order_type = 'all'
    }

    this.$bvModal.show('download-invoice-modal')
  }

  public confirmDownload() {
    Invoice.download(this.selected, this.download_formats, this.order_type).then(() => {
      WebMessage.success('Generating Files, do not close this window!')
    })
  }

  public markAsSent(invoice: Invoice | null = null) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    this.action_acknowledged = false
    this.mark_as_sent = true
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'txt']
    this.order_type = 'all'

    this.$bvModal.show('send-invoice-modal')
  }

  public sendInvoices(invoice: Invoice | null = null) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    this.action_acknowledged = false
    this.mark_as_sent = false
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'txt']
    this.order_type = 'all'

    this.$bvModal.show('send-invoice-modal')
  }

  public confirmSendInvoices() {
    if (this.hasSelectedUnapprovedInvoices && !this.action_acknowledged) {
      WebMessage.warning(
        'You have selected invoices that are not approved. Please review them before sending.',
      )
      return
    }
    Invoice.send(this.selected, this.order_type, this.mark_as_sent).then(() => {
      this.dataTable.refresh()
      WebMessage.success(
        'Invoices added to the email queue, the client should get the invoices shortly',
      )
    })
  }

  private isQuickbooksLinked() {
    if (!this.is_quickbooks_connected) {
      WebMessage.error(
        'You need to connect your Quickbookks account before performing this action.',
        [
          {
            text: 'Connect Now!',
            action: (toast: any) => {
              this.$router.push({ name: 'Account' })
              WebMessage.hide(toast.id)
            },
          },
        ],
      )
      return false
    }

    return true
  }

  public createInvoice() {
    if (this.overview.invoiceble_media_plans > 0) {
      this.$bvModal.show('create-invoice-modal')
      return
    }
    this.createInvoiceSingle()
  }

  public createInvoiceSingle() {
    if (!this.isQuickbooksLinked()) return
    this.$router.push({ name: 'Invoice' })
  }

  public createInvoiceBatch() {
    this.$router.push({ name: 'invoice-create-batch' })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('invoices').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices',
      data: null,
    })
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.dataTable.refresh()
  }

  public created() {
    this.records = this.invoices.length
  }

  public mounted() {
    this.fields = home_table_options
    this.loadFilters()
  }

  public getInvoicesOverview() {
    Invoice.getInvoicesOverview().then((result: any) => {
      this.overview = result
    })
  }

  public registerPayment(item: any) {
    if (!this.isQuickbooksLinked()) {
      return
    }

    this.temp_invoice = item
    setTimeout(() => {
      this.$bvModal.show('payment-modal')
    }, 100)
  }

  public confirmPayment() {
    let payment = new Payment()
    let {
      id, created_at, pay_amount, name, reference_code,
    } = this.temp_invoice
    payment = Object.assign(payment, {
      created_at,
      invoice_id: id,
      amount: pay_amount,
      reference_code,
    })
    payment.name = name
    this.loading = true
    payment.save().then(() => {
      this.loading = false
      this.$bvModal.hide('payment-modal')
      this.clearTempInvoice()
      setTimeout(() => {
        this.dataTable.refresh()
      }, 500)
    })
  }

  /**
   * Set all properties to null
   *
   * This is used when the old_values from invoice is empty
   *
   * @param obj
   */
  public nullObj(obj: any): any {
    return (
      Object.keys(obj).forEach(
        k => (obj[k] = obj[k] === Object(obj[k]) ? this.nullObj(obj[k]) : null),
      ),
      obj
    )
  }

  /**
   * Loads the given Invoice history
   * @param invoice
   */
  public viewHistory(invoice: Invoice) {
    this.history_loading = true
    this.modal.history = true
    this.invoice = invoice
    invoice
      .getHistory({
        page_size: 25,
        page: 1,
        order_by: 'created_at',
        order: 'desc',
        query: [],
      })
      .then((response: any) => {
        this.waitForData(response).then(r => {
          // dont permit the invoice to display info if you only edited the invoice item
          this.history = r.filter(h => h.details.length)
        })

        this.waitForItemData(response).then(r => {
          this.invoice_items_history = r.filter(h => h.details.length)
          this.history_loading = false
        })

        this.invoice_payments = response.data.result.payments.map(p => {
          p.payment_method = p.payment_method.replace('_', ' ')
          return p
        })
        this.history_records = response.data.result.records
      })
  }

  /**
   *  Maps the changes from The response of the invoice history
   *
   *  The changed payload will be injected in details
   *
   * @param response
   */
  public async waitForData(response: any) {
    return Promise.all(
      response.data.result.invoices.map(async i => {
        i.details = await this.checkDiff(i.old_values, i.new_values)
        return i
      }),
    )
  }

  public async waitForItemData(response: any) {
    return Promise.all(
      response.data.result.items.map(async i => {
        if (i.event === 'created') {
          i.old_values = this.nullObj(JSON.parse(JSON.stringify(i.new_values)))
        }
        i.details = await this.checkDiff(i.old_values, i.new_values)
        return i
      }),
    )
  }

  /**
   *  ref: https://davidwells.io/snippets/get-difference-between-two-objects-javascript
   *
   *  This function will return the changes in the given payload
   *
   *  @param origObj Is the old_values
   *  @param newObj  Is the new_values
   *
   */
  public difference(origObj: any, newObj: any) {
    const changes = (newObj: any, origObj: any) => {
      let arrayIndexCounter = 0
      return transform(newObj, (result: any, value: any, key: any) => {
        if (!isEqual(value, origObj[key])) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
          result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value
        }
      })
    }
    return changes(newObj, origObj)
  }

  /**
   * Format the payload in the given conditions
   *
   * This is where you want to create conditions, format the data before consuming it in the table.
   *
   * @param data Current value
   * @param key Current key from the object
   */
  public async checkData(data: any, key: any) {
    if (data === 'N/A') return data
    let users_table_check = ['sales_rep_id', 'account_manager_id']
    let check_numbers = [
      'due',
      'total',
      'tax',
      'net_subtotal',
      'gross_subtotal',
      'net_total',
      'gross_total',
      'net_rate',
      'gross_rate',
      'cost_total',
      'cost_rate',
    ]
    let check_date = ['due_at', 'broadcast_month']

    if (users_table_check.includes(key)) {
      // request and get user name and store in memory
      if (!this.loaded_informations[data]) {
        let res = this.user.findThisUserName(data)
        this.loaded_informations[data] = res
        return res
      }
      return this.loaded_informations[data]
    }

    if (check_numbers.includes(key)) {
      // Format currency
      let is_num = !isNaN(data)
      if (is_num) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          Number(data),
        )
      }
    }

    if (check_date.includes(key)) {
      // formate dates
      return moment(data).format('MM/DD/YYYY')
    }

    return data
  }

  /**
   * Removes repeated values that probably wasnt changed in the audit old_values to new_values
   * @param obj
   */
  public async removeRepeats(obj: any) {
    return new Promise((res, rej) => {
      if ((obj.old === 'N/A' && obj.new === 'N/A') || obj.old === obj.new) {
        res(false)
      }
      res(true)
    })
  }

  /**
   * This function is the core for checking all the diffrence between changes in the invoice.
   */
  public async checkDiff(old_values: any, new_values: any) {
    new_values.metadata = JSON.parse(new_values.metadata || 'null')
    old_values.metadata = JSON.parse(old_values.metadata || 'null')
    let diff: any = []
    let diff_values = this.difference(old_values, new_values)

    let add = (d: any) => diff.push(d)
    let clear = (s: any) => {
      if (isString(s)) {
        return s?.replaceAll('_', ' ')?.replaceAll('id', '')?.replace('00:00:00', '') || s
      }
      return s
      //
    }
    /**
     * Use only with the metadata object
     *
     * Here we loop the metadata object keys
     *
     * Create condition for formating any property inside of metadata object
     */
    let extract = async (old_meta: any, changed_meta: any) => {
      let chng_keys = Object.keys(changed_meta)
      let meta_key: any = null
      for await (meta_key of chng_keys) {
        if (isObject(changed_meta[meta_key])) {
          if (meta_key === 'client_data') {
            let client_data_keys = Object.keys(changed_meta[meta_key])

            for await (const cdk of client_data_keys) {
              let name = meta_key.replace('_data', ` ${cdk}`)

              let obj: any = {
                key: clear(name),
                old: !old_meta.client_data.hasOwnProperty(cdk)
                  ? 'N/A'
                  : old_meta[meta_key][cdk]
                    ? old_meta[meta_key][cdk]
                    : 'N/A',
                new: changed_meta[meta_key][cdk] ? changed_meta[meta_key][cdk] : 'N/A',
              }

              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'custom_billing') {
            let custom_billing_keys = Object.keys(changed_meta.custom_billing)

            for await (const cbk of custom_billing_keys) {
              let name = `${clear(meta_key)} ${cbk.replace('billing_', ' ')}`
              let obj: any = {
                key: name,
                old: old_meta.custom_billing[cbk] ? old_meta.custom_billing[cbk] : 'N/A',
                new: changed_meta.custom_billing[cbk] ? changed_meta.custom_billing[cbk] : 'N/A',
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'header') {
            let header_keys = Object.keys(changed_meta.header)
            for await (const hk of header_keys) {
              let name = clear(hk)
              let o = await this.checkData(old_meta.header[hk] || 'N/A', hk)
              let n = await this.checkData(changed_meta.header[hk] || 'N/A', hk)
              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'payee') {
            let payee_keys = Object.keys(changed_meta.payee)

            for await (const pk of payee_keys) {
              let name = `Payee ${clear(pk)}`
              let o = await this.checkData(old_meta.payee[pk] || 'N/A', pk)
              let n = await this.checkData(changed_meta.payee[pk] || 'N/A', pk)

              // console.log('the payee', { n, o })
              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'station') {
            let station_keys = Object.keys(changed_meta.station)

            for await (const sk of station_keys) {
              let name = `station ${clear(sk)}`
              let o = await this.checkData(old_meta.station[sk] || 'N/A', sk)
              let n = await this.checkData(changed_meta.station[sk] || 'N/A', sk)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }

          if (meta_key === 'view_columns') {
            let view_columns_values = Object.values(changed_meta.view_columns)
            let view_columns_old_values = Object.values(old_meta.view_columns)

            view_columns_old_values = view_columns_old_values.filter(vc => vc !== null)

            let n = view_columns_values.length
              ? view_columns_values.toString().replaceAll(',', ', ')
              : 'N/A'
            let o = view_columns_old_values.length
              ? view_columns_old_values.toString().replaceAll(',', ', ')
              : 'N/A'

            let obj: any = {
              key: 'View Columns',
              old: clear(o || ''),
              new: clear(n || ''),
            }
            let can_add = await this.removeRepeats(obj)
            if (can_add) add(obj)
          }

          if (meta_key === 'agency') {
            let agency_keys = Object.keys(changed_meta.agency)

            for await (const ak of agency_keys) {
              let name = `agency ${clear(ak)}`
              let o = await this.checkData(old_meta.agency[ak] || 'N/A', ak)
              let n = await this.checkData(changed_meta.agency[ak] || 'N/A', ak)

              let obj: any = {
                key: name,
                old: clear(o || ''),
                new: clear(n || ''),
              }
              let can_add = await this.removeRepeats(obj)
              if (can_add) add(obj)
            }
          }
        } else {
          let obj = {
            key: clear(meta_key),
            old: clear(old_meta[meta_key] || 'N/A'),
            new: clear(changed_meta[meta_key] || 'N/A'),
          }
          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
        // In case of array value add this
        // else if(isArray([])){}
      }
    }

    let ignore_keys = ['invoice_id', 'id']
    let keys = Object.keys(diff_values)
    for await (const k of keys) {
      if (diff_values[k] !== old_values[k] && k !== 'metadata') {
        if (!ignore_keys.includes(k)) {
          let o = await this.checkData(old_values[k] || 'N/A', k)
          let n = await this.checkData(new_values[k] || 'N/A', k)

          let obj = {
            key: clear(k),
            old: clear(o || ''),
            new: clear(n || ''),
          }

          let can_add = await this.removeRepeats(obj)
          if (can_add) add(obj)
        }
      }

      if (k === 'metadata') {
        // metadata must be extracted alone because of nested objects

        if (!Object.keys(old_values.metadata || {}).length) {
          old_values.metadata = this.nullObj(JSON.parse(JSON.stringify(diff_values.metadata)))
        }

        await extract(old_values.metadata, diff_values.metadata)
      }
    }

    return diff
  }

  public refresh() {
    this.dataTable.refresh()
  }
}

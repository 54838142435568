export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
    type: 'checkbox',
  },
  {
    key: 'id',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'media_plan',
    type: 'object',
    object: {
      key: 'media_plan',
      property: 'name',
    },
    label: 'Media Plan',
    sortable: true,
    show: true,
    class: 'text-center align-middle fixed-width',
  },
  {
    key: 'media_plan_item',
    label: 'Item',
    type: 'object',
    object: {
      key: 'media_plan_item',
      property: 'name',
    },
    sortable: true,
    class: 'text-center align-middle fixed-width',
    show: true,
  },

  {
    key: 'billing_month',
    label: 'Billing Month',
    type: 'date',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'start_at',
    label: 'Flight Dates',
    sortable: true,
    class: 'text-center align-middle fixed-width',
    show: true,
  },
  {
    key: 'booked_impressions',
    label: 'Booked Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'lifetime_impressions',
    label: 'Lifetime Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'billable_impressions',
    label: 'Billable Impressions',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'revenue_rate',
    label: 'Rate',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
    type: 'currency',
  },
  {
    key: 'revenue_total',
    label: 'Net Total',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
    type: 'currency',
  },
  {
    key: 'margin',
    label: 'Margin',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'status',
    label: 'status',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
]
